import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import api from './api'
import base from './api/base'

Vue.config.productionTip = false

Vue.prototype.$api = api

if(process.env.NODE_ENV == "development"){
  base.url = '/api'
}
if(process.env.NODE_ENV == "test"){
  base.url = 'http://api.tbay.com/api'
}
if(process.env.NODE_ENV == "production"){
  base.url = 'https://api.tbay.store/api'
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
